import { computed, defineComponent, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { Sex } from 'api/users/models/user/enums/Sex';
import AuthLayout from 'layouts/AuthLayout';
import Autocomplete from 'components/Autocomplete';
import { useApiErrors } from 'composables/apiErrors';
import { UsersMutation, useUsersMutation } from 'store/users';
import { Api } from 'api/api';
import { setThemeColors, ymSuccessfulRegister } from 'core/helpers';
import { ThemeColor } from 'api/users/models/user';

import type { Nullable, ICity } from 'core/types';
import Captcha from 'components/Captcha';
import { useCaptcha } from 'composables/captcha';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { useFetching } from 'composables/fetching';
import { CITIES_STORE_KEY, CitiesGetter } from 'store/cities';
import { useStore } from 'vuex';
import { RouteNames } from 'router/names';

const Signup = defineComponent({
  name: RouteNames.SignUp,
  components: {
    AuthLayout,
    Autocomplete,
    Captcha,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const { t } = useI18n();
    const store = useStore();

    const cities = computed<ICity[]>(() => store.getters[`${CITIES_STORE_KEY}/${CitiesGetter.Cities}`]);

    const { showError } = useApiErrors();

    const setAuthToken = useUsersMutation(UsersMutation.SetAuthToken);
    const showPassword = ref(false);
    const showRepeatPassword = ref(false);

    onMounted(() => {
      autocompleteCity();
    });

    const model = reactive<{
      name: string
      birthdate: string
      sex: Nullable<Sex>
      city: Nullable<ICity>
      email: string
      password: string
      repeatPassword: string
      promo?: string
    }>({
      name: '',
      birthdate: '',
      sex: null,
      city: null,
      email: '',
      password: '',
      repeatPassword: '',
    });

    watchEffect(() => {
      if (typeof route.query.promo === 'string') {
        model.promo = route.query.promo;
      } else {
        model.promo = undefined;
      }
    });
    const isPromoExist = computed(() => model.promo !== undefined);

    const shouldValidate = ref(false);
    const nameRef = ref();
    const emailRef = ref();
    const passwordRef = ref();
    const repeatPasswordRef = ref();
    const birthdateRef = ref();
    const cityRef = ref();
    const sexValidation = ref(true);

    const emailRe = /.+@.+\..+/;
    const rules = reactive({
      required: (v: Nullable<string | Sex>) => !!v || t('rule.required'),
      email: (v: string) => emailRe.test(v) || t('rule.required'),
      selected: (v: any) => !!v || t('rule.required'),
      passwordLength: (v: Nullable<string>) =>
        (v && v.length > 7) || t('rule.min', { n: 8 }),
      passwordConfirm: (v: Nullable<string>) =>
        (v && v === model.password) || t('rule.passwordNotMatch'),
      legal: (v: string) => {
        if (!v || v.length < 10) return t('rule.invalidDateFormat');
        const val = (v.split('.') ?? []).reverse().join('-');
        const date = dayjs(val);
        const now = dayjs();
        if (
          !date.isValid()
          || date.isAfter(now)
          || date.isBefore('1901-01-01')
          || dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') !== val
        ) {
          return t('rule.invalidDateFormat');
        }
        if (now.diff(date, 'year') > 17) {
          return true;
        }
        return t('rule.registerLegal');
      },
    });

    function validate() {
      shouldValidate.value = true;
      if (!nameRef.value || !birthdateRef.value || !emailRef.value || !passwordRef.value || !repeatPasswordRef.value) return false;
      const nameValidation = nameRef.value.validate();
      const birthdateValidation = birthdateRef.value.validate();
      const emailValidation = emailRef.value.validate();
      const passwordValidation = passwordRef.value.validate();
      const repeatPasswordValidation = repeatPasswordRef.value.validate();
      sexValidation.value = rules.required(model.sex) === true;
      const cityValidation = cityRef.value.validate();
      return nameValidation && birthdateValidation && sexValidation.value && cityValidation && emailValidation && passwordValidation && repeatPasswordValidation;
    }

    watch(
      () => model.sex,
      sex => setThemeColors(sex === Sex.Male ? ThemeColor.Blue : ThemeColor.Red),
    );

    const { validateCaptcha, fetch: captchaFetch, getCaptchaRequest } = useCaptcha();

    const { isFetching, fetch } = useFetching();
    const onSubmitWrapper = () => fetch(onSubmit());

    async function onSubmit() {
      if (!validateCaptcha()) return;
      if (validate()) {
        try {
          const { data } = await captchaFetch(Api.userService.register(getCaptchaRequest({
            name: model.name,
            email: model.email,
            password: model.password,
            password_confirmation: model.repeatPassword,
            birth: model.birthdate,
            gender: model.sex as Sex,
            promo: model.promo || undefined,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            city_id: model.city!.id as number,
          })));

          if (data.token) {
            setAuthToken(data.token);
            ymSuccessfulRegister();
            await router.push('/success#edit');
          }
        } catch (error) {
          showError(error);
        }
      }
    }

    function onSexChange(sex: Sex) {
      model.sex = sex;
      sexValidation.value = true;
      setThemeColors(sex === Sex.Male ? ThemeColor.Blue : ThemeColor.Red);
    }

    async function autocompleteSex(name: string) {
      const mens = [
        'Абрам', 'Август', 'Адам', 'Адриан', 'Аким', 'Александр', 'Алексей', 'Альберт',
        'Ананий', 'Анатолий', 'Андрей', 'Антон', 'Антонин', 'Аполлон', 'Аркадий', 'Арсений',
        'Артемий', 'Артур', 'Артём', 'Афанасий', 'Богдан', 'Болеслав', 'Борис', 'Бронислав', 'Вадим',
        'Валентин', 'Валериан', 'Валерий', 'Василий', 'Вениамин', 'Викентий', 'Виктор',
        'Виль', 'Виталий', 'Витольд', 'Влад', 'Владимир', 'Владислав', 'Владлен', 'Всеволод',
        'Вячеслав', 'Гавриил', 'Гарри', 'Геннадий', 'Георгий', 'Герасим', 'Герман', 'Глеб', 'Гордей',
        'Григорий', 'Давид', 'Дан', 'Даниил', 'Данила', 'Денис', 'Дмитрий', 'Добрыня', 'Донат', 'Евгений', 'Егор',
        'Ефим', 'Захар', 'Иван', 'Игнат', 'Игнатий', 'Игорь', 'Илларион', 'Илья', 'Иммануил', 'Иннокентий', 'Иосиф',
        'Ираклий', 'Кирилл', 'Клим', 'Константин', 'Кузьма', 'Лаврентий', 'Лев', 'Леонид', 'Макар', 'Максим', 'Марат',
        'Марк', 'Матвей', 'Милан', 'Мирослав', 'Михаил', 'Назар', 'Нестор', 'Никита', 'Никодим', 'Николай', 'Олег',
        'Павел', 'Платон', 'Прохор', 'Пётр', 'Радислав', 'Рафаил', 'Роберт', 'Родион', 'Роман', 'Ростислав', 'Руслан',
        'Сава', 'Савва', 'Святослав', 'Семён', 'Сергей', 'Спартак', 'Станислав', 'Степан', 'Стефан', 'Тарас',
        'Тимофей', 'Тимур', 'Тит', 'Трофим', 'Феликс', 'Филипп', 'Фёдор', 'Эдуард', 'Эрик', 'Юлиан', 'Юлий', 'Юрий',
        'Яков', 'Ян', 'Ярослав', 'Милан',
      ];
      const women = [
        'Александра', 'Алина', 'Алиса', 'Алла', 'Альбина', 'Алёна', 'Анастасия', 'Анжелика', 'Анна', 'Антонина',
        'Анфиса', 'Валентина', 'Валерия', 'Варвара', 'Василиса', 'Вера', 'Вероника', 'Виктория', 'Владлена', 'Галина',
        'Дарья', 'Диана', 'Дина', 'Доминика', 'Ева', 'Евгения', 'Екатерина', 'Елена', 'Елизавета', 'Жанна', 'Зинаида',
        'Злата', 'Зоя', 'Изабелла', 'Изольда', 'Инга', 'Инесса', 'Инна', 'Ирина', 'Искра', 'Капитолина', 'Клавдия',
        'Клара', 'Клементина', 'Кристина', 'Ксения', 'Лада', 'Лариса', 'Лидия', 'Лилия', 'Любовь', 'Людмила', 'Люся',
        'Майя', 'Мальвина', 'Маргарита', 'Марина', 'Мария', 'Марта', 'Надежда', 'Наталья', 'Нелли', 'Ника', 'Нина',
        'Нонна', 'Оксана', 'Олеся', 'Ольга', 'Полина', 'Рада', 'Раиса', 'Регина', 'Рената', 'Розалина', 'Светлана',
        'Софья', 'София', 'Таисия', 'Тамара', 'Татьяна', 'Ульяна', 'Фаина', 'Федосья', 'Флорентина', 'Эльвира',
        'Эмилия', 'Эмма', 'Юлия', 'Яна', 'Ярослава',
      ];
      if (mens.includes(name)) {
        model.sex = Sex.Male;
      } else if (women.includes(name)) {
        model.sex = Sex.Female;
      }
    }

    function autocompleteCity() {
      const apiKey = '9344371c-1d66-411d-9acf-920bb130f16';
      const yandexMapScript = loadYmapsScriptFile(apiKey);

      yandexMapScript.addEventListener('load', () => {
        onloadYandexMaps(handleDeterminedCity);
      });
    }

    function loadYmapsScriptFile(apiKey: string) {
      const yandexMapScript = document.createElement('script');
      const url = `https://api-maps.yandex.ru/2.0-stable/?load=package.standard&lang=ru_RU&amp;apikey=${apiKey}`;
      yandexMapScript.setAttribute('src', url);
      document.head.appendChild(yandexMapScript);

      return yandexMapScript;
    }

    function onloadYandexMaps(handle: any) {
      initYmaps();

      handle();
    }

    function initYmaps() {
      const yandexMapScriptText = document.createElement('script');
      yandexMapScriptText.setAttribute('type', 'text/javascript');
      yandexMapScriptText.innerHTML = `ymaps.ready(function () {
            if(ymaps.geolocation) {
              let city = document.getElementById('city');
              city.setAttribute('data-city', ymaps.geolocation.city)
            }
          });
        `;
      document.head.appendChild(yandexMapScriptText);
    }

    function handleDeterminedCity() {
      const city = document.getElementById('city');
      if (city === null) {
        return;
      }

      const observer = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach(async (mutation: MutationRecord) => {
          if (mutation.type !== 'attributes' || mutation.attributeName !== 'data-city') {
            return;
          }
          const currentCityName = city.getAttribute('data-city');
          if (currentCityName === null) {
            return;
          }

          updateCurrentCityIfNeeded(currentCityName);
        });
      });

      observer.observe(city, {
        attributes: true,
      });
    }

    function updateCurrentCityIfNeeded(currentCity: string) {
      const currentCityObject = cities.value.find(item => item.name === currentCity);
      if (currentCityObject !== undefined) {
        model.city = currentCityObject;
        cityRef.value = currentCityObject;
      }
    }

    return {
      cities,
      showPassword,
      showRepeatPassword,
      shouldValidate,
      nameRef,
      emailRef,
      passwordRef,
      repeatPasswordRef,
      birthdateRef,
      cityRef,
      sexValidation,
      rules,
      isPromoExist,
      isFetching,
      model,
      onSubmitWrapper,
      onSexChange,
      autocompleteSex,

      Sex,
      IconType,
      IconName,
      Size,
    };
  },
});

export default Signup;
