import { defineComponent, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import type { Nullable } from 'core/types';
import AuthLayout from 'layouts/AuthLayout';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useApiErrors } from 'composables/apiErrors';
import { UsersMutation, useUsersMutation } from 'store/users';
import { Api } from 'api/api';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { RouteNames } from 'router/names';

const NewPassword = defineComponent({
  name: RouteNames.PasswordReset,
  components: {
    AuthLayout,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const showPassword = ref(false);
    const showConfirmPassword = ref(false);

    const { showError } = useApiErrors();

    const setAuthToken = useUsersMutation(UsersMutation.SetAuthToken);

    const model = reactive<{
      password: string
      passwordConfirm: string
    }>({
      password: '',
      passwordConfirm: '',
    });
    const shouldValidate = ref(false);
    const passwordRef = ref();
    const passwordConfirmRef = ref();

    const rules = reactive({
      required: (v: Nullable<string>) => !!v || t('rule.required'),
      passwordLength: (v: Nullable<string>) =>
        (v && v.length > 7) || t('rule.min', { n: 8 }),
      passwordConfirm: (v: Nullable<string>) =>
        (v && v === model.password) || t('rule.passwordNotMatch'),
    });

    function validate() {
      shouldValidate.value = true;
      if (!passwordRef.value || !passwordConfirmRef.value) return false;
      const passwordValidation = passwordRef.value.validate();
      const passwordConfirmValidation = passwordConfirmRef.value.validate();
      return passwordValidation && passwordConfirmValidation && route.query.login && route.query.token;
    }

    async function onSubmit() {
      if (validate()) {
        try {
          const { data } = await Api.userService.passwordReset(
            route.query.login as string,
            model.password,
            model.passwordConfirm,
            route.query.token as string,
          );

          toastEmitter.emit('toast', {
            type: ToastType.Success,
            message: t('pages.newPassword.successMessage'),
          });

          if (data.token) {
            setAuthToken(data.token);
            router.push('/');
          } else {
            router.push('/signin');
          }
        } catch (error) {
          showError(error);
        }
      }
    }

    return {
      showPassword,
      showConfirmPassword,

      shouldValidate,
      passwordRef,
      passwordConfirmRef,
      rules,
      model,
      onSubmit,
    };
  },

  data() {
    return {
      IconName,
      IconType,
      Size,
    };
  },
});

export default NewPassword;
