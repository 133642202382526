<template>
  <auth-layout
    :title="$t('pages.signup.title')"
    show-back-btn
    back-btn-to="/"
  >
    <form
      novalidate="true"
      class="auth-layout-form"
      @submit.prevent="onSubmitWrapper"
    >
      <textfield
        ref="nameRef"
        v-model="model.name"
        type="text"
        :floating-label="`${$t('pages.signup.name')} *`"
        :rules="[rules.required]"
        :should-validate="shouldValidate"
        @input="autocompleteSex($event.target.value)"
      />

      <textfield
        ref="birthdateRef"
        v-model="model.birthdate"
        type="text"
        inputmode="tel"
        mask="##.##.####"
        class="signup-birthdate-input"
        :floating-label="`${$t('pages.signup.birthdate')} *`"
        :placeholder="$t('pages.signup.birthdateFormat')"
        :rules="[rules.legal]"
        :should-validate="shouldValidate"
      />
      <autocomplete
        id="city"
        ref="cityRef"
        v-model="model.city"
        :label="`${$t('pages.signup.city')} *`"
        :items="cities"
        two-line
        no-filter
        :rules="[rules.selected]"
        item-value="id"
        item-text="name"
        select
      >
        <template #item="{ item }">
          <div>{{ item.name }}</div>
          <div>{{ item.region }}</div>
        </template>
      </autocomplete>
      <textfield
        ref="emailRef"
        v-model="model.email"
        type="text"
        :floating-label="`${$t('pages.signup.email')} *`"
        :rules="[rules.email]"
        :should-validate="shouldValidate"
      />
      <textfield
        ref="passwordRef"
        v-model="model.password"
        :type="showPassword ? 'text' : 'password'"
        :floating-label="`${$t('pages.signup.password')} *`"
        :rules="[rules.passwordLength]"
        :should-validate="shouldValidate"
      >
        <template #right>
          <icon-btn
            :size="Size.M"
            class="auth-layout-toggle-button"
            @click="showPassword = !showPassword;"
          >
            <icon
              :type="IconType.Contour"
              :name="showPassword ? IconName.EyeClosed : IconName.Eye"
              :size="Size.M"
            />
          </icon-btn>
        </template>
      </textfield>
      <textfield
        ref="repeatPasswordRef"
        v-model="model.repeatPassword"
        :type="showRepeatPassword ? 'text' : 'password'"
        :floating-label="`${$t('pages.signup.repeatPassword')} *`"
        :rules="[rules.passwordConfirm]"
        :should-validate="shouldValidate"
      >
        <template #right>
          <icon-btn
            :size="Size.M"
            class="auth-layout-toggle-button"
            @click="showRepeatPassword = !showRepeatPassword;"
          >
            <icon
              :type="IconType.Contour"
              :name="showRepeatPassword ? IconName.EyeClosed : IconName.Eye"
              :size="Size.M"
            />
          </icon-btn>
        </template>
      </textfield>
      <textfield
        v-if="isPromoExist"
        v-model="model.promo"
        type="text"
        :floating-label="`${$t('pages.signup.promo')} *`"
      />
      <div
        :class="{
          'signup-toggle': true,
          'signup-toggle--has-error': !sexValidation,
        }"
      >
        <Btn
          :class="{
            'signup-toggle__button': true,
            'signup-toggle__button--active': model.sex === Sex.Male,
          }"
          @click.prevent="onSexChange(Sex.Male)"
        >
          {{ $t('pages.signup.male') }}
        </Btn>
        <Btn
          type="button"
          :class="{
            'signup-toggle__button': true,
            'signup-toggle__button--active': model.sex === Sex.Female,
          }"
          @click.prevent="onSexChange(Sex.Female)"
        >
          {{ $t('pages.signup.female') }}
        </Btn>
      </div>
      <captcha />
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit"
        :disabled="isFetching"
      >
        {{ $t('pages.signup.registerNow') }}
      </Btn>
    </form>

    <router-link
      to="/signin"
      class="btn btn_text auth-layout-link"
    >
      {{ $t('pages.signup.haveAccount') }}
    </router-link>

    <i18n-t
      keypath="pages.signup.agreement"
      tag="div"
      class="signup-agreement"
    >
      <template #policy>
        <router-link to="/agreement">
          {{ $t('pages.signup.policy') }}
        </router-link>
      </template>
      <template #privacy>
        <router-link to="/agreement/privacy">
          {{ $t('pages.signup.privacy') }}
        </router-link>
      </template>
      <template #legal>
        <span>{{ $t('pages.signup.legalAge') }}</span>
      </template>
    </i18n-t>
  </auth-layout>
</template>

<script lang="ts" src="./Signup.ts"></script>
<style lang="scss" src="./signup.scss"></style>
