import { defineComponent } from 'vue';
import { useCaptcha } from 'composables/captcha';

const Captcha = defineComponent({
  setup() {
    return {
      ...useCaptcha(),
    };
  },
});

export default Captcha;
