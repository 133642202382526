import { ComputedRef, reactive, Ref } from 'vue';
import { Nullable } from 'core/types';
import { formatPhoneNumber, isEmail } from 'core/helpers';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { usersFilterValueDefaults } from 'core/values';

const emailRe = /.+@.+\..+/;

export function useRules(opts = {
    password: 8,
    phoneLength: 12,
    legal: {
      minDate: '1950-01-01',
      minAge: 18,
    },
  }) {
  const { t } = useI18n();

  const rules = reactive({
    select: (v: Nullable<string>) => !!v || t('rule.select'),
    required: (v: Nullable<string>) => !!v || t('rule.required'),
    phone: (v: string) => v.length > 16 || t('rule.required'),
    email: (v: string) => emailRe.test(v) || t('rule.required'),
    phoneOrEmail: (v: Nullable<string>) => {
      if (!v) return t('rule.required');
      if (isEmail(v)) {
        return emailRe.test(v) ? true : t('rule.required');
      }
      if (formatPhoneNumber(v).length === opts.phoneLength) return true;
      return t('rule.required');
    },
    password: (v: Nullable<string>) => !!(v && v.length >= opts.password) || t('rule.min', { n: opts.password }),
    legal: (v: string) => {
      if (v.length < 10) return t('rule.legal.incorrect');
      const val = (v.split('.') ?? []).reverse().join('-');
      const date = dayjs(val);
      const now = dayjs();
      if (
        !date.isValid()
        || date.isAfter(now)
        || date.isBefore(opts.legal.minDate)
        || dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') !== val
      ) {
        return t('rule.legal.incorrect');
      }
      const old = now.diff(date, 'year');
      if (old >= opts.legal.minAge) {
        return true;
      }
      return t('rule.legal.younger');
    },
    height: (v: string) => {
      const num = Number(v);
      return (!Number.isNaN(num) && num >= usersFilterValueDefaults.height.from && num <= usersFilterValueDefaults.height.to) || t('rule.incorrect');
    },
  });

  const passwordConfirm = (password: Ref<string> | ComputedRef<string>) => (v: Nullable<string>) =>
    (v && v === password.value) || t('rule.passwordNotMatch');

  const minLength = (length: number) => (v: Nullable<string>) =>
    (typeof v === 'string' && (!v.length || v.trim().length >= length)) || t('rule.minLength', { n: length });

  const maxLength = (length: number) => (v: Nullable<string>) =>
    (typeof v === 'string' && v.trim().length <= length) || t('rule.maxLength', { n: length });

  return {
    rules,
    passwordConfirm,
    minLength,
    maxLength,
  };
}
