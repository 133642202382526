import { defineComponent, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import AuthLayout from 'layouts/AuthLayout';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useApiErrors } from 'composables/apiErrors';
import { Api } from 'api/api';
import { formatPhoneNumber, isEmail } from 'core/helpers';
import { useRules } from 'composables/rules';
import { useCaptcha } from 'composables/captcha';
import Captcha from 'components/Captcha';
import { useFetching } from 'composables/fetching';
import { RouteNames } from 'router/names';

const PasswordRestore = defineComponent({
  name: RouteNames.ProfilePasswordRestore,
  components: {
    AuthLayout,
    Captcha,
  },

  props: {
    profile: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const router = useRouter();
    const { t } = useI18n();

    const { showError } = useApiErrors();

    const model = reactive<{
      email: string
    }>({
      email: '',
    });
    const shouldValidate = ref(false);
    const loginRef = ref();

    function validate() {
      shouldValidate.value = true;
      if (!loginRef.value) return false;
      const validation = loginRef.value.validate();
      return validation;
    }

    const { validateCaptcha, fetch: captchaFetch, getCaptchaRequest, fetchCaptcha } = useCaptcha();
    fetchCaptcha();

    const { fetch, isFetching } = useFetching();
    const onSubmitWrapper = () => fetch(onSubmit());

    async function onSubmit() {
      if (!validateCaptcha()) return;
      // validate then redirect to signin with props to modify layout
      if (validate()) {
        try {
          const { data } = await captchaFetch(Api.userService.requestPassword(getCaptchaRequest({
            login: isEmail(model.email) ? model.email : formatPhoneNumber(model.email),
          })), false);

          if (data.result === true) {
            toastEmitter.emit('toast', {
              type: ToastType.Success,
              message: t('pages.passwordRestore.successMessage'),
            });
            router.push(props.profile ? '/profile' : '/signin');
          }
        } catch (error) {
          fetchCaptcha();
          showError(error);
        }
      }
    }

    return {
      ...useRules(),
      shouldValidate,
      loginRef,
      model,
      isFetching,
      onSubmitWrapper,
    };
  },
});

export default PasswordRestore;
