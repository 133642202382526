import { ref } from 'vue';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import { Api } from 'api/api';

interface CaptchaRequest {
  captcha?: string;
  key?: string;
}

const captcha = ref<CaptchaRequest>({
  captcha: undefined,
  key: undefined,
});

const captchaInputRef = ref();
const captchaImage = ref<string | undefined>('');

export function useCaptcha(showCaptchaAlert = true) {
  const { t } = useI18n();
  captcha.value = {
    captcha: undefined,
    key: undefined,
  };
  captchaInputRef.value = undefined;
  captchaImage.value = undefined;

  async function fetchCaptcha() {
    const { data } = await Api.captchaService.getCaptcha();
    if (data?.captcha && data?.captcha.img && data?.captcha.key) {
      captcha.value = {
        key: data.captcha.key,
        captcha: '',
      };
      captchaImage.value = data.captcha.img;
    }
  }

  function validateCaptcha() {
    if (!captchaImage.value) return true;

    const isCaptchaValid = !!captcha.value.captcha;
    if (!isCaptchaValid && captchaInputRef.value) captchaInputRef.value.focus();
    return isCaptchaValid;
  }

  function getCaptchaRequest<T>(data: T): T & CaptchaRequest {
    return {
      ...data,
      ...captcha.value,
    };
  }

  function clearCaptcha() {
    captcha.value = {
      key: undefined,
      captcha: undefined,
    };
    captchaImage.value = undefined;
  }

  async function fetch(fn: Promise<any>, autoClear = true) {
    try {
      const data = await fn;
      const response = data?.data;
      if (response?.captcha && response?.captcha.img && response?.captcha.key) {
        captcha.value = {
          key: response.captcha.key,
          captcha: '',
        };
        captchaImage.value = response.captcha.img;
        if (showCaptchaAlert) {
          toastEmitter.emit('toast', {
            type: ToastType.Error,
            message: t('components.captcha.enterTheCaptcha'),
          });
        }
      } else if (autoClear) {
        clearCaptcha();
      }
      return data;
    } catch (e) {
      if (autoClear) clearCaptcha();
      throw e;
    }
  }

  return {
    captchaInputRef,
    captcha,
    captchaImage,
    fetch,
    fetchCaptcha,
    validateCaptcha,
    getCaptchaRequest,
    clearCaptcha,
  };
}
