<template>
  <main>
    <ModalHeader
      :title="$t('pages.profile.authLog')"
      :dir="ModalHeaderDirection.Ltr"
      relative
      @close="back"
    >
      <template #close-icon>
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
          :size="Size.S"
        />
      </template>
    </ModalHeader>

    <div class="auth-log">
      <div
        v-for="item in authLog"
        class="auth-log__item auth-log-item"
      >
        <div
          class="auth-log-item__icon"
          v-html="getIcon(item)"
        />

        <div class="auth-log-item__info">
          <span class="auth-log-item__header">{{ item.device_title }} • {{ item.ip }}</span>
          <span class="auth-log-item__time">{{ formatDate(item.created_at) }}</span>
        </div>
      </div>

      <list-loader
        v-show="authLog.length !== 0"
        :is-loading="isFetching"
        :has-next="hasNext"
        @load="fetchMore"
      />
    </div>
  </main>
</template>

<script lang="ts" src="./AuthLog.ts" />
<style lang="scss" src="./authLog.scss" />
