<template>
  <auth-layout
    :title="$t('pages.signin.title')"
    show-back-btn
    back-btn-to="/"
  >
    <form
      novalidate="true"
      class="auth-layout-form"
      @submit.prevent="onSubmitWrapper"
    >
      <textfield
        ref="loginRef"
        v-model="model.login"
        type="text"
        :floating-label="$t('pages.signin.login')"
        :rules="[rules.required, rules.phoneOrEmail]"
        :should-validate="shouldValidate"
      />
      <textfield
        ref="passwordRef"
        v-model="model.password"
        :type="showPassword ? 'text' : 'password'"
        :floating-label="$t('pages.signin.password')"
        :rules="[rules.required, rules.password]"
        :should-validate="shouldValidate"
        class="signin__password-input"
      >
        <template #right>
          <icon-btn
            :size="Size.M"
            class="auth-layout-toggle-button"
            @click="showPassword = !showPassword;"
          >
            <icon
              :type="IconType.Contour"
              :name="showPassword ? IconName.EyeClosed : IconName.Eye"
              :size="Size.M"
            />
          </icon-btn>
        </template>
      </textfield>

      <captcha />

      <router-link
        to="/password-restore"
        class="btn btn_text signin-forgot-link"
      >
        {{ $t('pages.signin.forgotPassword') }}
      </router-link>
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit mt-3"
        :disabled="isFetching"
      >
        {{ $t('pages.signin.logIn') }}
      </Btn>
    </form>

    <router-link
      to="/signup"
      class="btn btn_text auth-layout-link"
    >
      {{ $t('pages.signin.registerNow') }}
    </router-link>
  </auth-layout>
</template>

<script lang="ts" src="./Signin.ts"></script>
<style lang="scss" src="./signin.scss"></style>
