<template>
  <auth-layout
    :title="$t('pages.passwordRestore.title')"
  >
    <form
      novalidate="true"
      class="auth-layout-form"
      @submit.prevent="onSubmitWrapper"
    >
      <textfield
        ref="loginRef"
        v-model="model.email"
        type="text"
        :floating-label="$t('pages.passwordRestore.login')"
        :rules="[rules.required, rules.phoneOrEmail]"
        :should-validate="shouldValidate"
      />
      <captcha />
      <Btn
        action-type="submit"
        w="100%"
        :disabled="isFetching"
        class="auth-layout-form__submit"
      >
        {{ $t('pages.passwordRestore.restorePassword') }}
      </Btn>
    </form>

    <router-link
      :to="profile ? '/profile/password-change' : '/signin'"
      class="btn btn_text auth-layout-link"
    >
      {{ $t('pages.passwordRestore.rememberedPassword') }}
    </router-link>
  </auth-layout>
</template>

<script lang="ts" src="./PasswordRestore.ts"></script>
