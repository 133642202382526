import { computed, defineComponent } from 'vue';
import { RouteNames } from 'router/names';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import { useRouter } from 'vue-router';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { useFetching } from 'composables/fetching';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { AuthLog } from 'api/users/models/authLog/interfaces';
import { DeviceType } from 'api/users/models/authLog/enums';

import ListLoader from 'components/ListLoader';
import PhoneIcon from './assets/phone.raw.svg';
import PCIcon from './assets/pc.raw.svg';

const AuthLog = defineComponent({
  name: RouteNames.AuthLog,
  components: {
    ModalHeader,
    ListLoader,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const authLog = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.AuthLog}`]);
    const hasNext = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.AuthLogHasNext}`]);

    const back = () => router.back();

    const { fetch, isFetching } = useFetching();
    function fetchMore() {
      fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.FetchAuthLog}`, true));
    }

    function formatDate(dateStr: string) {
      const date = dayjs(new Date(dateStr));

      if (date.isToday()) return date.format(`${t('pages.profile.today')} в HH:mm`);
      if (date.isYesterday()) return date.format(`${t('pages.profile.yesterday')} в HH:mm`);

      return date.format('DD.MM.YYYY в HH:mm');
    }

    function getIcon(item: AuthLog) {
      if (item.device_type === DeviceType.Mobile) return PhoneIcon;
      return PCIcon;
    }

    return {
      authLog,
      hasNext,
      fetchMore,
      isFetching,
      formatDate,
      back,
      getIcon,

      ModalHeaderDirection,
      IconType,
      IconName,
      Size,
    };
  },
});

export default AuthLog;
