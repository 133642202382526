<template>
  <div v-if="captchaImage" class="captcha">
    <img
      class="captcha__img"
      :src="captchaImage"
      alt="Captcha"
    >
    <textfield
      ref="captchaInputRef"
      v-model="captcha.captcha"
      class="captcha__input"
      :placeholder="`${$t('components.captcha.textfieldPlaceholder')} *`"
    />
  </div>
</template>

<script lang="ts" src="./Captcha.ts" />
<style lang="scss" src="./captcha.scss" />
