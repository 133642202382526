<template>
  <auth-layout
    :title="$t('pages.newPassword.title')"
  >
    <form
      novalidate="true"
      class="auth-layout-form mb-4"
      @submit.prevent="onSubmit"
    >
      <textfield
        ref="passwordRef"
        v-model="model.password"
        :type="showPassword ? 'text' : 'password'"
        :floating-label="$t('pages.newPassword.newPassword')"
        :rules="[rules.required, rules.passwordLength]"
        :should-validate="shouldValidate"
      >
        <template #right>
          <icon-btn
            :size="Size.M"
            class="auth-layout-toggle-button"
            @click="showPassword = !showPassword;"
          >
            <icon
              :type="IconType.Contour"
              :name="showPassword ? IconName.EyeClosed : IconName.Eye"
              :size="Size.M"
            />
          </icon-btn>
        </template>
      </textfield>
      <textfield
        ref="passwordConfirmRef"
        v-model="model.passwordConfirm"
        :type="showConfirmPassword ? 'text' : 'password'"
        :floating-label="$t('pages.newPassword.repeatPassword')"
        :rules="[rules.required, rules.passwordLength, rules.passwordConfirm]"
        :should-validate="shouldValidate"
      >
        <template #right>
          <icon-btn
            :size="Size.M"
            class="auth-layout-toggle-button"
            @click="showConfirmPassword = !showConfirmPassword;"
          >
            <icon
              :type="IconType.Contour"
              :name="showConfirmPassword ? IconName.EyeClosed : IconName.Eye"
              :size="Size.M"
            />
          </icon-btn>
        </template>
      </textfield>
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit"
      >
        {{ $t('pages.newPassword.setNewPassword') }}
      </Btn>
    </form>
  </auth-layout>
</template>

<script lang="ts" src="./NewPassword.ts"></script>
