import { defineComponent, reactive, ref, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import type { Nullable } from 'core/types';
import AuthLayout from 'layouts/AuthLayout';
import { toastEmitter, ToastType } from 'components/Toasts';
import Stepper from 'components/Stepper';
import CodeInput from 'components/CodeInput';
import { BtnType } from 'components/Btn';
import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';
import { RouteNames } from 'router/names';

enum Steps {
  Phone = 'phone',
  PhoneCode = 'phoneCode',
  Email = 'email',
  EmailCode = 'emailCode',
  PasswordReset = 'passwordReset',
}

const FillData = defineComponent({
  name: RouteNames.FillData,
  components: {
    AuthLayout,
    Stepper,
    CodeInput,
  },

  setup() {
    const router = useRouter();
    const { t, te } = useI18n();

    const step = ref(Steps.Phone);

    const model = reactive<{
      phone: string
      phoneCode: string
      email: string
      emailCode: string
      password: string
      passwordConfirm: string
    }>({
      phone: '',
      phoneCode: '',
      email: '',
      emailCode: '',
      password: '',
      passwordConfirm: '',
    });
    const shouldValidate = ref(false);
    const phoneRef = ref();
    const phoneCodeRef = ref();
    const emailRef = ref();
    const emailCodeRef = ref();
    const passwordRef = ref();
    const passwordConfirmRef = ref();
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const emailRe = /.+@.+\..+/;
    const phoneCodeValid = ref(false);
    const emailCodeValid = ref(false);
    const timer = ref(0);
    let timerId: number | undefined;

    const rules = reactive({
      required: (v: Nullable<string>) => !!v || t('rule.required'),
      phone: (v: string) => v.length > 16 || t('rule.required'),
      email: (v: string) => emailRe.test(v) || t('rule.required'),
      passwordLength: (v: Nullable<string>) =>
        (v && v.length > 7) || t('rule.min', { n: 8 }),
      passwordConfirm: (v: Nullable<string>) =>
        (v && v === model.password) || t('rule.passwordNotMatch'),
    });

    const pageStep = computed(() => {
      switch (step.value) {
        case 'phone':
        case 'phoneCode': return 1;
        case 'email':
        case 'emailCode': return 2;
        case 'passwordReset': return 3;
        default: return 1;
      }
    });

    const title = computed(() => t(`pages.fillData.${step.value}.title`));

    const subtitle = computed(() => {
      const path = `pages.fillData.${step.value}.subtitle`;
      return te(path, 'ru') ? t(path) : '';
    });

    watch(step, (val, oldVal) => {
      shouldValidate.value = false;
      if (val === Steps.PhoneCode || val === Steps.EmailCode) {
        clearTimer();
        timer.value = 59;
        timerId = window.setTimeout(timerTick, 1000);
      }
      if (oldVal === Steps.PhoneCode || oldVal === Steps.EmailCode) {
        clearTimer();
      }
    }, {
      flush: 'post',
    });

    function timerTick() {
      if (--timer.value <= 0) {
        clearTimer();
      } else {
        timerId = window.setTimeout(timerTick, 1000);
      }
    }

    function clearTimer() {
      window.clearInterval(timerId);
      timerId = undefined;
      timer.value = 0;
    }

    function validatePhone() {
      if (!phoneRef.value) return false;
      return phoneRef.value.validate();
    }

    function validateCode(val: Nullable<string>) {
      return Boolean(val && val.length === 4);
    }

    function validatePhoneCode(val: Nullable<string>) {
      model.phoneCode = val || '';

      phoneCodeValid.value = validateCode(val);

      if (phoneCodeValid.value) {
        step.value = Steps.Email;
        nextTick(() => {
          emailRef.value?.focus();
        });
      }

      return phoneCodeValid.value;
    }

    function validateEmail() {
      if (!emailRef.value) return false;
      return emailRef.value.validate();
    }

    function validateEmailCode(val: Nullable<string>) {
      model.emailCode = val || '';

      emailCodeValid.value = validateCode(val);

      if (emailCodeValid.value) {
        step.value = Steps.PasswordReset;
        nextTick(() => {
          passwordRef.value?.focus();
        });
      }

      return emailCodeValid.value;
    }

    function validatePassword() {
      if (!passwordRef.value || !passwordConfirmRef.value) return false;
      const passwordValidation = passwordRef.value.validate();
      const passwordConfirmValidation = passwordConfirmRef.value.validate();
      return passwordValidation && passwordConfirmValidation;
    }

    function validate() {
      shouldValidate.value = true;

      switch (step.value) {
        case 'phone': return validatePhone();
        case 'phoneCode': return validatePhoneCode(model.phoneCode);
        case 'email': return validateEmail();
        case 'emailCode': return validateEmailCode(model.emailCode);
        case 'passwordReset': return validatePassword();
        default: return false;
      }
    }

    function onSubmit() {
      if (validate()) {
        if (step.value === Steps.Phone) {
          step.value = Steps.PhoneCode;
          nextTick(() => {
            phoneCodeRef.value?.focus();
          });
        } else if (step.value === Steps.PhoneCode) {
          step.value = Steps.Email;
          nextTick(() => {
            emailRef.value?.focus();
          });
        } else if (step.value === Steps.Email) {
          step.value = Steps.EmailCode;
          nextTick(() => {
            emailCodeRef.value?.focus();
          });
        } else if (step.value === Steps.EmailCode) {
          step.value = Steps.PasswordReset;
          nextTick(() => {
            passwordRef.value?.focus();
          });
        } else if (step.value === Steps.PasswordReset) {
          toastEmitter.emit('toast', {
            type: ToastType.Success,
            message: t('pages.fillData.passwordReset.successMessage'),
          });
          router.push('/signin');
        }
      }
    }

    function resendCode(type: Steps.PhoneCode | Steps.EmailCode) {
      if (timer.value) return;
      const message = t(`pages.fillData.${type}.successMessage`);
      toastEmitter.emit('toast', {
        type: ToastType.Success,
        message: t(message),
      });
    }

    return {
      timer,
      step,
      pageStep,
      title,
      subtitle,
      shouldValidate,
      phoneRef,
      phoneCodeRef,
      phoneCodeValid,
      emailRef,
      emailCodeRef,
      emailCodeValid,
      passwordRef,
      passwordConfirmRef,
      rules,
      model,
      showPassword,
      showConfirmPassword,
      validatePhoneCode,
      validateEmailCode,
      onSubmit,
      resendCode,
    };
  },

  data: () => ({
    Steps,
    BtnType,
    IconType,
    IconName,
    Size,
  }),
});

export default FillData;
