<template>
  <auth-layout>
    <stepper
      :model-value="pageStep"
      class="auth-stepper"
    />

    <h3
      class="auth-layout__title mb-5"
    >
      {{ title }}
    </h3>

    <div
      v-if="subtitle"
      class="auth__subtitle"
    >
      {{ subtitle }}
    </div>

    <form
      v-if="step === Steps.Phone"
      novalidate="true"
      class="auth-layout-form mb-4"
      @submit.prevent="onSubmit"
    >
      <textfield
        ref="phoneRef"
        v-model="model.phone"
        :floating-label="$t('pages.fillData.phone.placeholder')"
        :rules="[rules.required, rules.phone]"
        :should-validate="shouldValidate"
        :mask="$t('mask.phone')"
        type="tel"
        inputmode="tel"
      />
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit mt-2"
      >
        {{ $t('pages.fillData.phone.submit') }}
      </Btn>
    </form>

    <form
      v-else-if="step === Steps.PhoneCode"
      novalidate="true"
      class="auth-layout-form mb-4"
      @submit.prevent="onSubmit"
    >
      <code-input
        ref="phoneCodeRef"
        :model-value="model.phoneCode"
        :err="shouldValidate && !phoneCodeValid"
        @update:model-value="validatePhoneCode"
        @submit="onSubmit"
      />
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit mt-2 mb-0"
      >
        {{ $t('pages.fillData.phoneCode.submit') }}
      </Btn>
      <btn
        :type="BtnType.Text"
        :disabled="!!timer"
        center
        @click="resendCode(Steps.PhoneCode)"
      >
        {{ $t('pages.fillData.phoneCode.requestAgain') }}
        <span
          v-if="timer"
          style="width: 24px;"
        > 0:{{ timer }}</span>
      </btn>
    </form>

    <form
      v-else-if="step === Steps.Email"
      novalidate="true"
      class="auth-layout-form mb-4"
      @submit.prevent="onSubmit"
    >
      <textfield
        ref="emailRef"
        v-model="model.email"
        :floating-label="$t('pages.fillData.email.placeholder')"
        :rules="[rules.required, rules.email]"
        :should-validate="shouldValidate"
        type="email"
      />
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit mt-2"
      >
        {{ $t('pages.fillData.email.submit') }}
      </Btn>
    </form>

    <form
      v-else-if="step === Steps.EmailCode"
      novalidate="true"
      class="auth-layout-form mb-4"
      @submit.prevent="onSubmit"
    >
      <code-input
        ref="emailCodeRef"
        :model-value="model.emailCode"
        :err="shouldValidate && !emailCodeValid"
        @update:model-value="validateEmailCode"
        @submit="onSubmit"
      />
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit mt-2 mb-0"
      >
        {{ $t('pages.fillData.emailCode.submit') }}
      </Btn>
      <btn
        :type="BtnType.Text"
        :disabled="!!timer"
        center
        @click="resendCode(Steps.EmailCode)"
      >
        {{ $t('pages.fillData.emailCode.requestAgain') }}
        <span
          v-if="timer"
          style="width: 24px;"
        > 0:{{ timer }}</span>
      </btn>
    </form>

    <form
      v-else-if="step === Steps.PasswordReset"
      novalidate="true"
      class="auth-layout-form mb-4"
      @submit.prevent="onSubmit"
    >
      <textfield
        ref="passwordRef"
        v-model="model.password"
        :type="showPassword ? 'text' : 'password'"
        :floating-label="$t('pages.fillData.passwordReset.newPassword')"
        :rules="[rules.required, rules.passwordLength]"
        :should-validate="shouldValidate"
      >
        <template #right>
          <icon-btn
            :size="Size.M"
            class="auth-layout-toggle-button"
            @click="showPassword = !showPassword;"
          >
            <icon
              :type="IconType.Contour"
              :name="showPassword ? IconName.EyeClosed : IconName.Eye"
              :size="Size.M"
            />
          </icon-btn>
        </template>
      </textfield>
      <textfield
        ref="passwordConfirmRef"
        v-model="model.passwordConfirm"
        :type="showConfirmPassword ? 'text' : 'password'"
        :floating-label="$t('pages.fillData.passwordReset.repeatPassword')"
        :rules="[rules.required, rules.passwordLength, rules.passwordConfirm]"
        :should-validate="shouldValidate"
      >
        <template #right>
          <icon-btn
            :size="Size.M"
            class="auth-layout-toggle-button"
            @click="showConfirmPassword = !showConfirmPassword;"
          >
            <icon
              :type="IconType.Contour"
              :name="showConfirmPassword ? IconName.EyeClosed : IconName.Eye"
              :size="Size.M"
            />
          </icon-btn>
        </template>
      </textfield>
      <Btn
        action-type="submit"
        w="100%"
        class="auth-layout-form__submit mt-2"
      >
        {{ $t('pages.fillData.passwordReset.submit') }}
      </Btn>
    </form>
  </auth-layout>
</template>

<script lang="ts" src="./FillData.ts"></script>
