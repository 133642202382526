import { defineComponent, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import AuthLayout from 'layouts/AuthLayout';
import { toastEmitter, ToastType } from 'components/Toasts';
import { UsersMutation, useUsersMutation } from 'store/users';
import { useApiErrors } from 'composables/apiErrors';
import { Api } from 'api/api';

import Captcha from 'components/Captcha';
import { useCaptcha } from 'composables/captcha';
import { useFetching } from 'composables/fetching';
import { formatPhoneNumber, isEmail } from 'core/helpers';
import { useRules } from 'composables/rules';
import { RouteNames } from 'router/names';

const Signin = defineComponent({
  name: RouteNames.SignIn,
  components: {
    AuthLayout,
    Captcha,
  },

  setup() {
    const router = useRouter();
    const { t } = useI18n();

    const { showError } = useApiErrors();

    const setAuthToken = useUsersMutation(UsersMutation.SetAuthToken);

    const model = reactive<{
      login: string
      password: string
    }>({
      login: '',
      password: '',
    });
    const shouldValidate = ref(false);
    const loginRef = ref();
    const passwordRef = ref();
    const showPassword = ref(false);

    const { validateCaptcha, fetch: captchaFetch, getCaptchaRequest } = useCaptcha();
    const { isFetching, fetch } = useFetching();
    const onSubmitWrapper = () => fetch(onSubmit());

    function validate() {
      shouldValidate.value = true;
      if (!loginRef.value || !passwordRef.value) return false;
      const nameValidation = loginRef.value.validate();
      const passwordValidation = passwordRef.value.validate();

      return nameValidation && passwordValidation;
    }

    async function onSubmit() {
      if (!validateCaptcha()) return;
      if (validate()) {
        try {
          const { data } = await captchaFetch(Api.userService.login(getCaptchaRequest({
            password: model.password,
            login: isEmail(model.login) ? model.login : formatPhoneNumber(model.login),
          })));

          if (data.token) {
            setAuthToken(data.token);
            await router.push('/');
          } else {
            if (data.message === 'Bad credentials.') {
              throw new Error(t('errors.api.userNotFound'));
            }
            if (data.errors && data.message) throw new Error(data.message);
          }
        } catch (error) {
          showError(error);
        }
      } else {
        toastEmitter.emit('toast', {
          type: ToastType.Error,
          message: t('pages.signin.errorMessage'),
        });
      }
    }

    return {
      ...useRules(),
      shouldValidate,
      loginRef,
      passwordRef,
      showPassword,
      model,
      isFetching,
      onSubmitWrapper,

      Size,
      IconName,
      IconType,
    };
  },
});

export default Signin;
